import { React } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { useUserOrderList } from '../../../../hooks/useUserOrderList';
import { useModal } from '../../../../providers/ModalProvider';
import formatNumberForView, { formatPriceForView, formatQuantityForView } from '../../../../utils/format';
import PrimaryButton from '../../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Buttons/SecondaryButton/SecondaryButton';
import SearchInput from '../../../Inputs/SearchInput';
import Loader from '../../../Loader';
import Pagination from '../../Pagination';

import { getProductsDetails, reorderProducts } from './utils';

import './styles.scss';

const OrderList = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const modal = useModal();
  const dispatch = useDispatch();
  const { isLoading, rows, page, setPage, filters, updateFilter, totalPages, perPage, setPerPage, resetFilter } =
    useUserOrderList({});

  const reorder = async (order) => {
    modal.showLoading({
      allowOutsideClick: false,
    });

    try {
      const orderResponse = await axios.post(
        '/v1/order-detail',
        {
          customerid: dataUser.ACCOUNTNUM,
          orderid: order.SALESID,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.token}`,
          },
        }
      );

      const products = await getProductsDetails(orderResponse?.data?.result?.data['SalesTable']);
      //console.log('Products details:', products);
      //const test = await reorderProducts(modal, t, dispatch, dataUser, orderResponse?.data?.result?.data['SalesTable'][0], products);
      try { const test = await reorderProducts(modal, t, dispatch, dataUser, orderResponse?.data?.result?.data['SalesTable'][0], products); 
        //console.log('Reorder products result:', test);


        } 
        catch (error) { console.error('Errore durante il reordinamento dei prodotti:', error);

        }

    } catch (error) {
      //console.error('reorder function',error);
      modal.showAttention({ subtitle: t('oops_something_wrong') });
    }
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="orders-list">
      <div className="orders-filter">
        <div className="left">
          <div className="search_field">
            <SearchInput
              placeholder={t('search_order')}
              value={filters.salesId}
              onChange={(event) => {
                setPage(1);
                updateFilter('salesId', event.target.value);
              }}
            />
          </div>
          <div className="search_field date">
            <span>{t('common.date_from')}:</span>
            <input
              type="date"
              name="startDate"
              placeholder="Start date"
              value={filters.startDate}
              onChange={(event) => {
                setPage(1);
                updateFilter('startDate', event.target.value);
              }}
            />
          </div>
          <div className="search_field date">
            <span>{t('common.date_to')}:</span>
            <input
              type="date"
              name="endDate"
              placeholder="End date"
              value={filters.endDate}
              onChange={(event) => {
                setPage(1);
                updateFilter('endDate', event.target.value);
              }}
            />
          </div>
          <div className="search_field">
            <select
              name="status"
              id="status"
              value={filters.status}
              onChange={(event) => {
                setPage(1);
                updateFilter('status', event.target.value);
              }}
            >
              <option value="" selected disabled>
                Status
              </option>
              <option value="Ordine aperto">{t('Ordine aperto')}</option>
              <option value="Fatturato">{t('Fatturato')}</option>
              <option value="Consegnato">{t('Consegnato')}</option>
            </select>
          </div>
          <div className="search_field date">
            <SecondaryButton onClick={resetFilter}>{t('common.filter_reset')}</SecondaryButton>
          </div>
        </div>
        <div className="right">
          <div className="per-page">
            <span>{t('order_number')}</span>
            <select name="per_page" id="per_page" value={perPage} onChange={(event) => setPerPage(event.target.value)}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="content_data" id="orders-table">
            <div className="order_table_header">
              <div className="order_table_row">
                <div className="order_table_title sku">{t('order_number')}</div>
                <div className="order_table_title data">{t('date')}</div>
                <div className="order_table_title utente">{t('User')}</div>
                <div className="order_table_title spedizione">{t('common.shipping')}</div>
                <div className="order_table_title consegna">{t('common.reference')}</div>
                <div className="order_table_title articoli">{t('common.total_items')}</div>
                <div className="order_table_title quantita">{t('common.total_qty')}</div>
                <div className="order_table_title stato">{t('Status')}</div>
                <div className="order_table_title totale">{t('common.total')}</div>
                <div className="order_table_title actions"></div>
              </div>
            </div>
            <div className="order_table_body">
              {rows.length > 0 &&
                rows.map((item, index) => {
                  return (
                    <div className="order_table_row" key={index}>
                      <div className="order_table_column sku" data-label="Numero ordine">
                        <Link to={'/order-detail/' + item.SALESID + '/' + item.SALESSTATUS}>{item.SALESID}</Link>
                      </div>
                      <div className="order_table_column data" data-label="Data">
                        <Moment date={item.BFSALESDATE} format="YYYY-MM-DD" />
                      </div>
                      <div className="order_table_column utente" data-label="Utente">
                        master
                      </div>
                      <div className="order_table_column spedizione" data-label="Spedizione">
                        <div className="sub_data">{item.DELIVERYADDRESS}</div>
                      </div>
                      <div className="order_table_column consegna" data-label="Data di consegna">
                        <div className="sub_data">
                          <div className="order_table_column utente" data-label="Utente">
                            {/* <Moment date={item.DELIVERYDATE} format="YYYY-MM-DD"   /> */}
                            {item.CUSTOMERREF}
                          </div>
                        </div>
                      </div>
                      <div className="order_table_column articoli" data-label="Totale articoli">
                        {formatNumberForView(item.TOTALLINES, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                      </div>
                      <div className="order_table_column quantita" data-label="Totale quantità">
                        {formatQuantityForView(item.TOTALKG)}
                      </div>
                      <div className="order_table_column stato" data-label="Stato">
                        {item.SALESSTATUS}
                      </div>
                      <div className="order_table_column totale" data-label="Totale">
                        {formatPriceForView(item.TOTAL, item.CURRENCYCODE)}
                      </div>
                      <div className="order_table_column actions">
                        <PrimaryButton onClick={() => reorder(item)}>{t('Riordina')}</PrimaryButton>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {!(page === 1 && rows.length < perPage) ? (
            <Pagination
              className="pagination-bar"
              currentPage={page - 1} // Why should we add 1?
              totalCount={rows.length < perPage ? perPage * (page - 1) + perPage : perPage * page + 1}
              pageSize={perPage}
              onPageChange={(page) => setPage(page + 1)} // Why should we minus 1?
              onPageClickScrollHref="orders-table"
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default OrderList;