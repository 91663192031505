import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  Redirect,
  useNavigate,
  useLocation,
  Navigate,
  Routes,
} from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import { USER_LOGIN_DATA } from '../../constants/ActionTypes';
import { useModal } from '../../providers/ModalProvider';
import { registerPaths } from '../../providers/TranslatedSlugProvider';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';

const Login = () => {
  const dispatch = useDispatch();
  const [field_type, SetField_type] = useState('password');
  const { t, i18n } = useTranslation();
  const loader = useLoader({ paddingBottom: '24px' });

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
  }, []);
  const modal = useModal();
  const handleSubmit = async (event) => {
    event.preventDefault();

    var FormData = require('form-data');
    var data = new FormData();
    data.append('email', event.target['email'].value);
    data.append('password', event.target['password'].value);
    loader.setStartLoading();
    loader.watchPromise(
      axios({
        method: 'post',
        url: '/v1/signIn',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {
          if(response.status == 200 && response.data?.redirect =="/change-password"){
            navigate('/change-password', { state: { email: response.data?.email } });
          }
          if (response.status == 200) {
            dispatch({
              type: USER_LOGIN_DATA,
              userdetailData: response?.data?.result?.data,
            });
            localStorage.setItem('token', response?.data?.result?.data?.token);
            localStorage.setItem('userDataDetail', JSON.stringify(response?.data?.result?.data));
            if (response?.data?.result?.data?.is_agent) {
              localStorage.setItem('agentDataDetail', JSON.stringify(response?.data?.result?.data));
            }
            window.location.href = '/';
            
          }
          //get userdatadetail if password_changed is false
          /*console.log(response?.data);
          if (!response?.data?.result?.data?.password_changed) {
            window.location.href = '/change-password';
          }*/
          
        })
        .catch(function (err) {
          if (err.response) {
            modal.showAttention({ subtitle: t("L'utente non è stato trovato") });
          }
        })
    );
  };

  return (
    <div className="main_content account_login">
      <div className="login_section">
        <div className="login_wrapper container">
          <div className="login_title">
            <h2>{t('accedi_all_area_reservata')}</h2>
            <p>{t('accedi_al_tuo_account_per_visualizzare')}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-input user_field">
              <input type="text" name="email" placeholder={t('common.username_required')} />
            </div>
            <div className="form-input password_field">
              <input type={field_type} name="password" placeholder={t('common.password_required')} />
              <span
                onClick={(e) => {
                  e.preventDefault();
                  SetField_type(field_type == 'password' ? 'text' : 'password');
                }}
                className={field_type == 'password' ? 'eye_icon' : 'eye_icon'}
              />
            </div>
            <div className="forgot_link">
              <Link to="/forgot-password">{t('common.password_dimenticata')}</Link>
            </div>
            <div className="action">{loader.asJsx || <button type="submit">{t('common.accedi')}</button>}</div>
          </form>
          <div className="create_link">
            <p>{t('common.non_hai_un_account')}</p>
            <p>
              <a href="#"></a>
            </p>
            <p>
              <Link className="login" to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                {t('common.richiedi_accesso')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
