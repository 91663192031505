import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import moment from 'moment';
import { useSearchParams, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Slider from 'react-slick';

import axios from 'axios';

import { useLoader } from '../../utils/hooks';
import LangLink from '../navigation/LangLink';

const NewsSlider = (props) => {
  const [category, SetCategory] = useState([]);
  const [news, SetNews] = useState([]);
  const [category_id, SetCategoryId] = useState(0);
  const { t, i18n } = useTranslation();
  const loader = useLoader({ height: '20vh' });

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          //   initialSlide: 2
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          //   initialSlide: 2
        },
      },
    ],
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id') || 0;

  useEffect(() => {
    const url = id != 0 ? '/v1/blog' : '/v1/blog?show_to_home_page=1';
    document.getElementById('home_page_class').classList.remove('home_page');
    axios.get(url).then((response) => {
      SetNews([...response.data.result.data.data]);
    });
  }, []);

  return (
    <>
      <div className="news_section blog_page ">
        <div className="news_wrapper">
          <div className="title">
            <h3>{props.title}</h3>
          </div>
          <div className="news_content seller_section ">
            {loader.asJsx || (
              <Slider {...settings}>
                {news.map((news, index) => (
                  <div className="news_item prova" key={index}>
                    <LangLink className="news_wrapper" to={'/news/' + news.slug}>
                      <img src={news.blog_base_image_url} alt="Digital" />
                      <div className="news_data">
                        <p className="tag  tal ">
                          <span className="date ">
                            {news.blog_date}
                            {/* <Moment format="D MMM YYYY">{news.blog_date}</Moment> */}
                          </span>
                          <span> | {news?.category?.name}</span>
                        </p>
                        <p className="name">{news.title}</p>
                        <p className="subtitle">{news.subtitle}</p>
                      </div>
                    </LangLink>
                  </div>
                ))}
              </Slider>
            )}
            <div className="container more_section">
              <div className="link">
                {loader.asJsx || (
                  <a href={`/${i18n.language}/news`}>
                    <button>
                      {t('mostra_altre_news')}{' '}
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsSlider;