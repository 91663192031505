import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import useDebounce from './useDebounce';

const availabilitySum = (items, prop) => {
  return items.reduce(function (a, b) {
    return parseFloat(a) + parseFloat(b[prop]);
  }, 0);
};

const getPrice = async (signal, dataUser, mainProduct, debouncedQty, colorName, currency = 'EUR') => {
  const response = await axios.post(
    '/v1/product_detail/get-price',
    {
      customerid: dataUser?.ACCOUNTNUM,
      itemid: mainProduct?.codice_articolo,
      quantity: debouncedQty,
      unitofmeasure: 'kg',
      currencycode: dataUser?.CURRENCY,
      configuration: mainProduct?.configid,
      colortone: colorName,
      configid: mainProduct?.configid,
    },
    {
      headers: {
        Authorization: `Bearer ${dataUser?.token}`,
      },
      signal,
    }
  );

  return response?.data?.result?.data?.SalesPrice || 0;
};

const getAvailability = async (signal, dataUser, mainProduct, debouncedQty, colorName, currentAvailable) => {
  const response = await axios.post(
    '/v1/product_detail/check-availability',
    {
      COD_PROD: mainProduct?.codice_articolo,
      COD_CLI: mainProduct?.configid,
      COD_COL: colorName,
    },
    {
      headers: {
        Authorization: `Bearer ${dataUser?.token}`,
      },
      signal,
    }
  );

  const MaxAvailPhysical = Math.max(...response?.data?.result?.data?.map((p) => p.AVAILPHYSICAL), 0);

  let newIsAvailableJson = [];
  let newGlobalAvailable = 'on demand';
  let newAvailable = 'on demand';
  let newIsOption = 0;

  if (
    response?.data?.result?.data?.length === 1 &&
    response?.data?.result?.data[0]?.AVAILPHYSICAL >= parseFloat(debouncedQty)
  ) {
    newIsAvailableJson = [...response?.data?.result?.data];
    newGlobalAvailable = 'Bagno unico';
    newAvailable = 'Bagno unico';
    newIsOption = 0;
  } else if (response?.data?.result?.data.length >= 2 && MaxAvailPhysical >= parseFloat(debouncedQty)) {
    newIsAvailableJson = [...response?.data?.result?.data];
    newGlobalAvailable = 'Bagno unico';
    newAvailable = 'Bagno unico';
    newIsOption = 0;
  } else if (
    response?.data?.result?.data.length >= 2 &&
    availabilitySum(response?.data?.result?.data, 'AVAILPHYSICAL') >= parseFloat(debouncedQty)
  ) {
    newIsAvailableJson = [...response?.data?.result?.data];
    newGlobalAvailable = 'option';
    newAvailable = !['Multibagno', 'on demand'].includes(currentAvailable) ? 'on demand' : currentAvailable;
    newIsOption = 1;
  }

  return {
    newIsAvailableJson,
    newGlobalAvailable,
    newAvailable,
    newIsOption,
  };
};

const getSupplement1 = async (signal, dataUser, mainProduct, debouncedQty, colorName, currency = 'EUR') => {

  console.log('Main supplemento',mainProduct);
  const response = await axios.post(
    '/v1/product_detail/get-suppliment-1',
    {
      customerid: dataUser?.ACCOUNTNUM,
      itemid: mainProduct?.codice_articolo,
      quantity: debouncedQty,
      currencycode: currency,
    },
    {
      headers: {
        Authorization: `Bearer ${dataUser?.token}`,
      },
      signal,
    }
  );
  return [
    response?.data?.result?.data?.markupAutoAmount || 0,
    response?.data?.result?.data?.errorDescription || '',
    response?.data?.result?.data?.markupType || ''
  ];
  
};

const getSupplement2 = async (signal, dataUser, mainProduct, currentCertification, currency = 'EUR') => {
  let newGlobalCertifications = [];
  let newSupplement2 = 0;
  let newCertification = currentCertification;


  if (mainProduct.CERTIFICATESGROUP_CGK_19052) {
    try{
      const availableCertificationsResponse = await axios.post(
        '/v1/product_detail/check-certificate',
        {
          certificate: mainProduct.CERTIFICATESGROUP_CGK_19052,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.token}`,
          },
          signal,
        }
      );

      newGlobalCertifications = availableCertificationsResponse?.data?.result?.data || [];
      console.log('newGlobalCertifications:', newGlobalCertifications);
    } catch (error) {
      console.error('Error:', error.message, error);
    }
  }

  let disabilita_supplemento = false;
  if(mainProduct.sub_produts){
    if(mainProduct.sub_produts.find((i) => i.codice_articolo === mainProduct?.codice_articolo)) {
      const check_prodotto_supplemento = mainProduct.sub_produts.find((i) => i.codice_articolo === mainProduct?.codice_articolo);
      console.log('check_prodotto_supplemento:', check_prodotto_supplemento);

      if(check_prodotto_supplemento.ExcludeOrganicCalc_CGK_25491 == 1) {
        disabilita_supplemento = true;
      } else {
        disabilita_supplemento = false;
      }
    }
  }
  

  

  if (currentCertification && newGlobalCertifications.find((i) => i.YARNTYPEID === currentCertification)) {

    try {
      const supplement2Response = await axios.post(
        '/v1/product_detail/get-supppliment-2',
        {
          customerid: dataUser?.ACCOUNTNUM,
          type: currentCertification,
          currencycode: currency,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.token}`,
          },
        }
      );

      console.log('supplement2Response:', supplement2Response);

      if(disabilita_supplemento) {
        newSupplement2 = 0;
      } else {
        newSupplement2 = supplement2Response?.data?.result?.data?.markupAutoAmount || 0;
      }
    } catch (error) {
      console.error('Error:', error.message, error);
    }
  } else {
    newCertification = null;
  }

  console.log('newGlobalCertifications:', newGlobalCertifications);
  console.log('newCertification:', newCertification);
  console.log('newSupplement2:', newSupplement2);

  return {
    newGlobalCertifications,
    newCertification,
    newSupplement2,
  };
};

const loadProductNewTotals = async (
  signal,
  dataUser,
  mainProduct,
  debouncedQty,
  colorName,
  currentAvailable,
  currentCertification,
  currency = 'EUR'
) => {
  const [newPrice, availabilityData, newSupplement, { newGlobalCertifications, newCertification, newSupplement2 }] =
    ///console.log('loadProductNewTotals:', mainProduct, debouncedQty, colorName, currentAvailable, currentCertification, currency);

    await Promise.all([
      getPrice(signal, dataUser, mainProduct, debouncedQty, colorName, currency),
      getAvailability(signal, dataUser, mainProduct, debouncedQty, colorName, currentAvailable),
      getSupplement1(signal, dataUser, mainProduct, debouncedQty, colorName, currency),
      getSupplement2(signal, dataUser, mainProduct, currentCertification, currency),
    ]);

  const { newIsAvailableJson, newGlobalAvailable, newAvailable, newIsOption } = availabilityData;

  
  return {
    newPrice,
    newIsAvailableJson,
    newGlobalAvailable,
    newAvailable,
    newIsOption,
    newSupplement,
    newGlobalCertifications,
    newCertification,
    newSupplement2,
  };
};

export const useProductTotals = ({
  mainProduct,
  colorName,
  qty,
  productInitialPrice = 0,
  productInitialAvailable = '',
  productInitialSupplement = 0,
  productInitialCertificate = null,
  productInitialSupplement2 = 0,
}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const debouncedQty = useDebounce(qty, 300);

  const [price, setPrice] = useState(productInitialPrice);
  const [isAvailableJson, setIsAvailableJson] = useState([]);
  const [globalAvailable, setGlobalAvailable] = useState(productInitialAvailable);
  const [available, setAvailable] = useState(productInitialAvailable);
  const [isOption, setIsOption] = useState(0);
  const [supplement, setSupplement] = useState(productInitialSupplement);
  const [globalCertifications, setGlobalCertifications] = useState([]);
  const [certification, setCertification] = useState(productInitialCertificate);
  const [supplement2, setSupplement2] = useState(productInitialSupplement2);

  // we update this after calling loadProductNewTotals, useful in cart when changing quantity because this way
  // we make sure product totals have been fetched, removing unnecessary requests to update the product
  const [quantityAfterProductTotals, setQuantityAfterProductTotals] = useState(qty);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!mainProduct) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
      setIsLoading(true);

      try {
        const [newPrice, availabilityData, newSupplement] = await Promise.all([
          getPrice(signal, dataUser, mainProduct, debouncedQty, colorName, dataUser?.CURRENCY),
          getAvailability(signal, dataUser, mainProduct, debouncedQty, colorName, available),
          getSupplement1(signal, dataUser, mainProduct, debouncedQty, colorName, dataUser?.CURRENCY),
        ]);

        const { newIsAvailableJson, newGlobalAvailable, newAvailable, newIsOption } = availabilityData;

        setPrice(newPrice);

        setIsAvailableJson(newIsAvailableJson);
        setGlobalAvailable(newGlobalAvailable);
        setAvailable(newAvailable);
        setIsOption(newIsOption);

        //setSupplement(newSupplement?.[0]|| 0);
        setSupplement(newSupplement);
      } catch (error) {
        console.error('Error:', error.message, error);
        
        if (error.response) {
          console.error('Response:', error.response.data);
        } else if (error.request) {
          console.error('Request:', error.request);
        } else {
          console.error('General Error:', error.message);
        }
      }

      setQuantityAfterProductTotals(debouncedQty);
      setIsLoading(false);
    };

    fetch();

    return () => {
      abortController.abort();
    };
  }, [mainProduct, debouncedQty, colorName, dataUser]);

  useEffect(() => {
    if (!mainProduct) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetch = async () => {
      setIsLoading(true);

      try {
        const { newGlobalCertifications, newCertification, newSupplement2 } = await getSupplement2(
          signal,
          dataUser,
          mainProduct,
          certification,
          dataUser?.CURRENCY
        );

        setGlobalCertifications(newGlobalCertifications);
        setCertification(newCertification);
        setSupplement2(newSupplement2);
      } catch (error) {
        console.error('Error:', error.message, error);
      }

      setIsLoading(false);
    };

    fetch();

    return () => {
      abortController.abort();
    };
  }, [mainProduct, certification, dataUser]);

  const unitPrice = useMemo(() => price + supplement2, [price, supplement2]);
  const totalSupplement = useMemo(() => supplement, [supplement]);
  const totalSupplement2 = useMemo(() => supplement2 * debouncedQty, [supplement2, debouncedQty]);
  const totalPrice = useMemo(() => unitPrice * debouncedQty + supplement?.[0], [unitPrice, debouncedQty, supplement]);

  return {
    price,
    unitPrice,
    totalPrice,
    totalSupplement,
    totalSupplement2,
    isAvailableJson,
    setIsAvailableJson,
    globalAvailable,
    available,
    setAvailable,
    isOption,
    supplement,
    supplement2,
    globalCertifications,
    certification,
    setCertification,
    isLoading,
    quantityAfterProductTotals,
  };
};

export { loadProductNewTotals };