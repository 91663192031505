import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSearchParams, useParams } from 'react-router-dom';

import axios from 'axios';

import { useLoader } from '../../utils/hooks';
import Slider_cms from '../layouts/home/slider';

import NewsSlider from './news_slider';

const News_Details = (props) => {
  const [news, SetNews] = useState('');
  const { t, i18n } = useTranslation();
  const loader = useLoader({ height: '90vh' });

  const params = useParams();
  const slug = params.slug;

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    loader.watchPromise(
      axios.get('/v1/blog-detail?slug=' + slug).then((response) => {
        SetNews(response.data.result.data);
      })
    );
  }, [slug]);

  return (
    <div className="main_content blog_detail pitti">

    {news.blog_base_image_url ? (
      <div className="image news-open-image">
        <img src={news.blog_base_image_url} alt="" />
      </div>
    ) : null}

      <div className="blog-content news-content">
        {loader.asJsx || (
          <>
            <div className="breadcrumb">
            <span style={{ color: '#c2c2c2' }}><a href="/">Home</a> | <a href={`/${i18n.language}/news`}>News</a> | {news?.category?.name} |</span> <span style={{ color: '#333E48' }}>{news?.title}</span>
            </div>
            
            <div className="title_section">
              <p>
                <Moment format="D MMM YYYY">{news.blog_date}</Moment>
              </p>
              <h2>{news?.title}</h2>
              <h4>{news?.subtitle}</h4>
            </div>

            <div dangerouslySetInnerHTML={{ __html: news.description }}></div>

            {news?.blog_image_url && news.blog_image_url.length > 0 ? (
              <div className="seller_section container news-grid-images">
                <Slider_cms images={news.blog_image_url || []} />
              </div>
            ) : null}

            <div class="prev-next-div">
              <div class="flex-pn-div">
                <div class="single-pn-flex div-prev">
                  <a>Articolo precedente</a>
                </div>
                <div class="single-pn-flex div-pn-center">
                  <a>{t('Precedente_news')}</a>
                  <a class="news-prev-button"></a>
                  <a class="news-next-button"></a>
                  <a>{t('Successivo_news')}</a>
                </div>
                <div class="single-pn-flex div-next">
                  <a>Articolo successivo</a>
                </div>
              </div>
            </div>
          </>
        )}
        <NewsSlider title={t('scopri_altre_news')} />
      </div>
    </div>
  );
};

export default News_Details;