import axios from 'axios';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../../../constants/ActionTypes';
import { loadProductNewTotals } from '../../../../hooks/useProductTotals';
import { cartPaths } from '../../../../providers/TranslatedSlugProvider';
import { isCustomProduct } from '../../../../utils/customProduct';
import { handleMultiCartTypeError } from '../../../../utils/errorHandling';
import { getTranslated } from '../../../../utils/translations';
import i18n from '../../../../i18n';


export const reorderProducts = async (modal, t, dispatch, dataUser, SalesTable, products) => {
  try {
    modal.showLoading({
      title: t('reorder.addingToCart.title'),
      subtitle: t('reorder.addingToCart.subtitle'),
      allowOutsideClick: false,
    });


    let countItemsAddedToCart = 0;

    
    try {
      const responses = await Promise.all(
        SalesTable?.SalesLine.map(async (value, index) => {
        //console.log(`Processing SalesLine item at index ${index}:`, value);  
        console.log('Itemid',value);
          try {
            const abortController = new AbortController();
            const signal = abortController.signal;
            
            const {
              newPrice,
              newIsAvailableJson,
              newGlobalAvailable,
              newAvailable,
              newIsOption,
              newSupplement,
              newSupplement2,
            } = await loadProductNewTotals(
              signal,
              dataUser,
              { codice_articolo: value.ItemId, configid: value.InventDim[0].ConfigId },
              value.QtyOrdered,
              value.InventDim[0].InventColorId,
              0,
              null,
              value.CurrencyCode
            );
            
            console.log('Received response for ItemId:', value.ItemId, {
              newPrice,
              newIsAvailableJson,
              newGlobalAvailable,
              newAvailable,
              newIsOption,
              newSupplement,
              newSupplement2,
            });

            if (parseFloat(newPrice) >= 0.1) {
              const isprecise = value?.PreciseCones_CGK_2014 === 1 ? 1 : 0;
              const response = await axios.post('/v1/add-to-cart', {
                config_id: value.InventDim[0].ConfigId,
                color_id: value.InventDim[0].InventColorId,
                product_name: products[index]?.nome_articolo || value?.InventDim[0].ConfigId,
                product_price: newPrice,
                quantity: value.QtyOrdered,
                client_id: dataUser.id,
                suppliment: newSupplement,
                suppliment2: newSupplement2,
                is_available_json: JSON.stringify({
                  suppliment: newSupplement,
                  suppliment2: newSupplement2,
                  available: newAvailable,
                  isprecise: isprecise,
                  isoption: newIsOption,
                  ItemId: value.ItemId,
                  is_available_json: newIsAvailableJson,
                }),
                precise_integer: isprecise,
                avelphysical: newAvailable,
                is_custom_product: isCustomProduct(dataUser, value.InventDim[0].ConfigId), // TODO: keep track of this one
              });

              if (response?.data?.result?.data?.cartQuantity) {
                countItemsAddedToCart += 1;
              }

              return response;
            }
            return null;
          } catch (error) {
            console.error('Errore ABORT CONTROLLER:', error);
            throw error;
          }
        })
      );
      console.log('responses', responses);
      if (countItemsAddedToCart > 0) {
        modal.showSuccess({
          title: t('reorder.cartUpdated.title'),
          subtitle: t('reorder.cartUpdated.subtitle', { count: countItemsAddedToCart }),
          hideCancelButton: false,
          cancelButtonText: t('CARRELLO'),
          onCancel: () => {
            window.location.href = getTranslated(i18n, cartPaths, { en: 'en', it: 'it' }, 'it');
          },
        });
        const max = Math.max(...responses.flat().map((r) => r.data.result.data?.cartQuantity || 0));
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: max,
        });
      } else {
        modal.showAttention({
          subtitle: t('reorder.noItemAvailable.subtitle'),
        });
      }
    } catch (error) {
      console.error('Errore durante il reordinamento dei prodotti:', error);
      if (error?.response?.data?.result?.type === 'multicart_type_error') {
        handleMultiCartTypeError(dataUser, modal, () => {}, dispatch, t);
      }
    }
  }
  catch (error) {
    console.error('Errore durante il reordinamento dei prodotti:', error);
    modal.showAttention({ subtitle: t('oops_something_wrong') });
  }
};

export const getProductsDetails = async (array) => {
  const dataToPost = [];
  const urls = [];
  let val = [];
  let i = 0;

  array?.map((data) => {
    data?.SalesLine?.map((val) => {
      dataToPost.push({
        id: val.ItemId,
        InventColorId: val?.InventDim[0].InventColorId,
      });

      urls.push('v1/product_detail/data');
    });
  });

  const axiosPostRequests = urls.map((url, index) => {
    return axios.post(url, dataToPost[index]).catch(() => ({}));
  });

  try {
    const responses = await Promise.all(axiosPostRequests);

    responses.forEach((response) => {
      val[i] = response?.data?.result?.data || {};
      i = 1 + i;
    });
  } catch (error) {
    console.error('An error occurred:', error);
  }

  return val;
};