import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Scrollbar,A11y } from 'swiper/modules';

import axios from 'axios';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useModal } from '../../../providers/ModalProvider';
import { productPaths } from '../../../providers/TranslatedSlugProvider';
import { useLoader } from '../../../utils/hooks';
import { getTranslated } from '../../../utils/translations';
import ContactForm from '../../ContactForm';
import NewsSlider from '../../pages/news_slider';
import Product_component from '../../product/product_component';

const Home = (props) => {
  const [page, Setpage] = useState(0);
  const [filters, setFilters] = useState([]);
  const { t, i18n } = useTranslation();
  const [nm, setNm] = useState([]);
  const [ne, setNe] = useState([]);
  const [finezza, setFinezza] = useState([]);
  const [utilizzo, setUtilizzo] = useState([]);
  const [fibra, setFibra] = useState([]);
  const [banner, setBanner] = useState([]);
  const [productdetails, Setproductdetails] = useState({});
  const [active_tab, Setactive_tab] = useState('best_seller');

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          //   initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };
  useEffect(() => {
    document.getElementById('home_page_class').classList.add(props.className);
    getAllFilters();
    axios.get('/v1/get-cms?slug=home').then((response) => {
      if (isNaN(response.data.result.data.content)) Setpage('<div>' + response.data.result.data.content + '</div>');
    });
    axios.get('/v1/product_list/category-wise-products').then((response) => {
      if (isNaN(response?.data?.result?.data)) Setproductdetails(response?.data?.result?.data);
    });
    axios.get('/v1/banners').then((response) => {
      if (isNaN(response?.data?.result?.data)) setBanner(response?.data?.result?.data);
    });
  }, []);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 400) {
      document.getElementById('home_page_class').classList.add('home_page');
    } else {
      document.getElementById('home_page_class').classList.remove('home_page');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const getAllFilters = () => {
    axios.post('v1/product_list/productfilters').then((response) => {
      setFilters(response?.data?.result?.data);
    });
  };

  const manageFilters = (value, filter, filter_values) => {
    if (!filter_values.includes(value)) {
      filter([...filter_values, value]);
    } else {
      const data = filter_values.filter(function (e) {
        return e !== value;
      });
      filter([...data]);
    }
  };

  return (
    <div className="main_content">
      <div className="hero_banner">
      <Swiper
      modules={[Autoplay,Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
        onPrev: (swiper) => {
          if (swiper.isBeginning) {
            swiper.slideTo(swiper.slides.length - 1); // Go to last slide
          }
        },
        onNext: (swiper) => {
          if (swiper.isEnd) {
            swiper.slideTo(0); // Go to first slide
          }
        },
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      loop={true} // Enable infinite loop
      >
      <SwiperSlide>
        <img src="/images/home/slider/slider_1.jpg"/>
        <div class="banner_content">
            <h1 class="white_text">I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_2.jpg"/>
        <div class="banner_content">
            <h1>I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_3.jpg"/>
        <div class="banner_content">
            <h1>I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_4.jpg"/>
        <div class="banner_content">
            <h1 class="white_text">I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_5.jpg"/>
        <div class="banner_content">
            <h1 class="white_text">I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_6.jpg"/>
        <div class="banner_content">
            <h1 class="white_text">I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_7.jpg"/>
        <div class="banner_content">
            <h1 class="white_text">I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/home/slider/slider_8.jpg"/>
        <div class="banner_content">
            <h1>I filati migliori per i brand di alta moda</h1>
            <div class="link"><a href="/it/prodotti">Vai ai filati</a></div>
        </div>
      </SwiperSlide>
      <div className="swiper-button-prev"></div> 
      <div className="swiper-button-next"></div>   
      </Swiper>
      </div>
      <div className="search_section">
        <div className="search_wrapper container">
          <div className="title title-box-ricerca">
            <h3>{t('ricerca_prodotto')}</h3>
          </div>
          <div className="search_dropdown">
            <div className="search_item composition">
              <label>{t('fibra')}</label>
              <select onChange={(event) => manageFilters(event.target.value, setFibra, fibra)}>
                <option readOnly>{t('common.Select')}</option>
                {filters?.fibra?.map((data) => (
                  <option key={data.value} value={data.value}>
                    {data.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="search_item nm">
              <label>Nm</label>
              <select onChange={(event) => manageFilters(event.target.value, setNm, nm)}>
                <option readOnly> {t('common.Select')} </option>
                {[...filters?.nm || []]
                  .sort((a, b) => {
                    // Funzione per convertire frazione in numero
                    const parseFraction = (str) => {
                      if (str.includes('/')) {
                        const [numerator, denominator] = str.split('/').map(Number);
                        return numerator / denominator;
                      }
                      return parseFloat(str); 
                    };

                    const valA = parseFraction(a.value);
                    const valB = parseFraction(b.value);

                    const isIntA = Number.isInteger(valA);
                    const isIntB = Number.isInteger(valB);

                    if (isIntA && !isIntB) return -1;
                    if (!isIntA && isIntB) return 1;

                    // Ordinare numericamente
                    return valA - valB;
                  })
                  .map((data) => (
                    <option key={data.value} value={data.value}>
                      {data.value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="search_item ne">
              <label>Ne</label>
              <select onChange={(event) => manageFilters(event.target.value, setNm, ne)}>
                <option readOnly>{t('common.Select')}</option>
                {filters?.ne
                  ?.slice() // Crea una copia dell'array per non modificarlo direttamente
                  .sort((a, b) => {
                    // Estrai i numeri prima e dopo lo slash
                    const [firstNumberA, secondNumberA] = a.value.split('/').map(Number);
                    const [firstNumberB, secondNumberB] = b.value.split('/').map(Number);
                    
                    // Compara prima i numeri prima dello slash e poi quelli dopo lo slash
                    if (firstNumberA === firstNumberB) {
                      return secondNumberA - secondNumberB;
                    } else {
                      return firstNumberA - firstNumberB;
                    }
                  })
                  .map((data) => (
                    <option key={data.value} value={data.value}>
                      {data.value}
                    </option>
                  ))}
              </select>
            </div>

            <div className="search_item finezza">
              <label>{t('finezza')} </label>
              <select onChange={(event) => manageFilters(event.target.value, setFinezza, finezza)}>
                <option readOnly> {t('common.Select')} </option>
                {filters?.finezza?.map((data) => (
                  <option value={data.value}>{data.value}</option>
                ))}
              </select>
            </div>
            <div className="search_item utilizzo">
              <label>{t('utilizzo')} </label>
              <select onChange={(event) => manageFilters(event.target.value, setUtilizzo, utilizzo)}>
                <option readOnly> {t('common.Select')} </option>
                <option value="1"> {t('select-banner-home.maglieria')} </option>
                <option value="2"> {t('select-banner-home.calzetteria')} </option>
                <option value="3"> {t('select-banner-home.tessitura')} </option>
              </select>
            </div>
            <div className="search_item search">
              <div className="action">
                <a
                  href="javascript:void(0);"
                  onClick={(e) => {
                    window.location.href =
                      getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it') +
                      '?fibra=' +
                      fibra +
                      '&ne=' +
                      nm +
                      '&nm=' +
                      ne +
                      '&finezza=' +
                      finezza +
                      '&utilizzo=' +
                      utilizzo;
                  }}
                >
                  {t('cerca')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="post__content" dangerouslySetInnerHTML={{ __html: page }}></div>

      <div className="seller_section textdecorationA">
        <div className="seller_wrapper container">
          <div className="title">
            <ul>
              <li className={active_tab == 'best_seller' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    Setactive_tab('best_seller');
                  }}
                  href="#"
                >
                  {t('best_seller')}
                </a>
              </li>
              <li className={active_tab == 'novita' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    Setactive_tab('novita');
                  }}
                  href="#"
                >
                  {t('novita')}
                </a>
              </li>
              <li className={active_tab == 'prodotti_eco' ? 'active' : ''}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    Setactive_tab('prodotti_eco');
                  }}
                  href="#"
                >
                  {t('prodotti_eco')}
                </a>
              </li>
            </ul>
          </div>
          {active_tab == 'best_seller' && (
            <div className="seller_content w-100 seller_data ">
              {productdetails?.best_selling ? (
                <>
                  <Slider {...settings}>
                    {productdetails?.best_selling.map((result, idx) => {
                      if (result.prodotti.length >= 1)
                        return <Product_component key={idx} data={{ slug: result.slug, ...result.prodotti[0] }} />;
                    })}
                  </Slider>
                </>
              ) : (
                ''
              )}
            </div>
          )}
          {active_tab == 'novita' && (
            <div className="seller_content w-100 seller_data ">
              {productdetails?.new_products ? (
                <>
                  <Slider {...settings}>
                    {productdetails?.new_products.map((result, idx) => {
                      if (result.prodotti.length >= 1)
                        return <Product_component key={idx} data={{ slug: result.slug, ...result.prodotti[0] }} />;
                    })}
                  </Slider>
                </>
              ) : (
                ''
              )}
            </div>
          )}
          {active_tab == 'prodotti_eco' && (
            <div className="seller_content w-100 seller_data ">
              {productdetails?.eco ? (
                <>
                  <Slider {...settings}>
                    {productdetails?.eco.map((result, idx) => {
                      if (result.prodotti.length >= 1)
                        return <Product_component key={idx} data={{ slug: result.slug, ...result.prodotti[0] }} />;
                    })}
                  </Slider>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div class="anchor_link_custom anchor_bottom_home" id="highlight"><a href="#highlight"><img class="down" alt="" src="https://www.filmar.it/admin/images/arrowdown_1736932461.png"/></a></div>
      {banner.length == 3 ? (
        <div className="highlight_section">
          <div className="highlight_wrapper container">
            <div className="highlight_wrap vertical_wrap">
              <div className="highlight_data azienda">
                <img className="fullscren_image" src={banner[0]?.desktop_image_url} alt="Sostenibilità" />
                <img className="mobile_img" src={banner[0]?.mobile_image_url} alt="Colorama" />
                <div className="highlight_content">
                  <span className="tag">{banner[0]?.title}</span>
                  <p className="desc">{banner[0]?.subtitle}</p>
                  <div className="link">
                    <a href={banner[0]?.page_url}>{t('scopri_di_piu')}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="highlight_wrap horizontal_wrap">
              <div className="highlight_data sosten">
                <img className="fullscren_image" src={banner[1]?.desktop_image_url} alt="Sostenibilità" />
                <img className="mobile_img" src={banner[1]?.mobile_image_url} alt="Colorama" />
                <div className="highlight_content">
                  <span className="tag">{banner[1]?.title}</span>
                  <p className="desc">{banner[1]?.subtitle}</p>
                  <div className="link">
                    <a href={banner[1]?.page_url}>{t('scopri_di_piu')}</a>
                  </div>
                </div>
              </div>
              <div className="highlight_data colorama">
                <img className="fullscren_image" src={banner[2]?.desktop_image_url} alt="Sostenibilità" />
                <img className="mobile_img" src={banner[2]?.mobile_image_url} alt="Colorama" />
                <div className="highlight_content">
                  <span className="tag">{banner[2]?.title}</span>
                  <p className="desc">{banner[2]?.subtitle}</p>
                  <div className="link">
                    <a href={banner[2]?.page_url}>{t('scopri_di_piu')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div class="anchor_link_custom anchor_bottom_home" id="form"><a href="#form"><img class="down" alt="" src="https://www.filmar.it/admin/images/arrowdown_1736932461.png"/></a></div>
      <ContactForm />
      <div className="news_section">
        <div className="news_wrapper container">
          <NewsSlider title={t('ultime_news_title')} />
        </div>
      </div>
    </div>
  );
};

export default Home;